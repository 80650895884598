import "./Review.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Map from "../../components/map/Map";
import { KindContact } from "../../components/detail/Detail";
import { ReviewBoxMain } from "../../components/review/Review-Box";
const Review = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="info-container">
              <div className="info-container-main-img"></div>
              <KindContact />
            </div>
            <ReviewBoxMain />
            <div className="review-container-more">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://m.map.naver.com/search2/site.naver?query=%EC%9D%B8%EC%B2%9C%EA%B4%91%EC%97%AD%EC%8B%9C%20%EA%B3%84%EC%96%91%EA%B5%AC%20%EC%98%A4%EC%A1%B0%EC%82%B0%EB%A1%9C%2053&style=v5&code=1631826674"
              >
                진요양원 블로그 리뷰 더보기
              </a>
            </div>
            <div className="review-container-more-desk">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://map.naver.com/v5/search/%EC%A7%84%EC%9A%94%EC%96%91%EC%9B%90/place/1631826674?c=14107547.5749966,4514044.8388237,15,0,0,0,dh&placePath=%3Fentry%253Dbmp"
              >
                진요양원 블로그 리뷰 더보기
              </a>
            </div>
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Review;
