import "./Detail.css";

export const IntroBar = ({ text, Icon }) => {
  return (
    <div className="intro-bar">
      <div className="intro-bar-logo">
        <Icon />
      </div>
      <h1 className="intro-bar-text">{text}</h1>
    </div>
  );
};

export const KindContact = () => {
  return (
    <div className="kind-contact-text">
      <a className="kind-contact-text-link" href="tel:032-511-8495">
        <strong>친절상담</strong> 032-511-8495
        <br />
      </a>
      <h5>
        요양원 비용에서 등급까지!
        <br />
        무엇이든 물어보세요
      </h5>
    </div>
  );
};
