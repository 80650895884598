import { useState, useEffect } from 'react';
import { Loading } from './components/loading/Loading';
import { RootRoutes } from './routes/Router';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
  }, []);
  return <>{loading === false ? <RootRoutes /> : <Loading />}</>;
}

export default App;
