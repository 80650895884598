import { NavLink } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const activeStyle = {
    color: "#07553B",
    fontWeight: "700",
  };

  return (
    <>
      <div className="header">
        <div className="header-logo">
          <NavLink to="/">
            <div className="header-logo-img"></div>
          </NavLink>
        </div>

        <div className="header-menu">
          <ul className="header-menu-list">
            <li className="header-menu-item">
              <NavLink activeStyle={activeStyle} to="/intro">
                <span className="header-menu-link">인사말</span>
              </NavLink>
            </li>
            <li className="header-menu-item">
              <NavLink activeStyle={activeStyle} to="/facility">
                <span className="header-menu-link">시설안내</span>
              </NavLink>
            </li>
            <li className="header-menu-item">
              <NavLink activeStyle={activeStyle} to="/rehabilitation">
                <span className="header-menu-link">재활치료</span>
              </NavLink>
            </li>
            <li className="header-menu-item">
              <NavLink activeStyle={activeStyle} to="/care">
                <span className="header-menu-link">케어서비스</span>
              </NavLink>
            </li>
            <li className="header-menu-item">
              <NavLink activeStyle={activeStyle} to="/info">
                <span className="header-menu-link">입소 및 비용</span>
              </NavLink>
            </li>
            <li className="header-menu-item">
              <NavLink activeStyle={activeStyle} to="/review">
                <span className="header-menu-link">네이버리뷰</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <article>
        <div className="contact-icons">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://open.kakao.com/o/s284vn3e"
          >
            <div className="kakaotalk-icon"></div>
          </a>
          <a href="tel:032-511-8495">
            <div className="phone-icon">
              <div className="phone-icon-hover"></div>
            </div>
          </a>
        </div>
      </article>
    </>
  );
};

export default Header;
