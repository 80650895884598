import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo"></div>
      <p className="footer-info">
        국민건강보험공단 장기요양기관 진 요양원 459-80-01896
        <br /> tel. 032-511-8495 fax.032-545-8495
        <br /> adress. 인천 계양구 오조산로53 연세차메디컬빌딩 8층, 9층
        <br /> COPYRIGHT(C) 진요양원. ALL RIGHT RESERVED
      </p>
    </footer>
  );
};

export default Footer;
