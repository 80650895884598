import { Link } from 'react-router-dom';
import { CareIntro } from '../../components/care-intro/Care-Intro';
import { IntroBar, KindContact } from '../../components/detail/Detail';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Map from '../../components/map/Map';
import { ReviewBox } from '../../components/review/Review-Box';
import { CareSlick } from '../../components/slicks/Slicks';
import { careIcon, ReviewStar } from '../../img/Icons';
import './Care.css';

const Care = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="care-container">
              <div className="care-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="전문케어서비스" Icon={careIcon} />
            <CareIntro />
            <div className="care-container-box-items">
              <div className="care-container-box-item">
                <div className="care-container-box-item-img1"></div>
                <p>어르신 전담 매니저</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img2"></div>
                <p>심신 건강체크</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img3"></div>
                <p>감정 밀착케어</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img4"></div>
                <p>모니터링 서비스</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img5"></div>
                <p>감정돌봄 서비스</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img6"></div>
                <p>건강체크 서비스</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img7"></div>
                <p>가족같은 돌봄서비스</p>
              </div>
            </div>
            <CareSlick />
            <div className="facility-container-blog facility-container-blog-main">
              <h1 className="facility-container-blog-title facility-container-blog-title-main">
                진요양원 블로그 바로가기
              </h1>
              <div className="facility-container-blog-list">
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri9902&logNo=222224676952&categoryNo=1&parentCategoryNo=-1&viewDate=&currentPage=&postListTopCurrentPage=&isAfterWrite=true"
                    rel="noreferrer"
                  >
                    치매 요양원 비용! 저렴하게 이용하는 법
                  </a>
                </div>
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri9902&logNo=222284279075&categoryNo=0&parentCategoryNo=1&viewDate=&currentPage=1&postListTopCurrentPage=1&from=postView"
                    rel="noreferrer"
                  >
                    인천요양원 추천! 소문난 진 요양원
                  </a>
                </div>
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri9902&logNo=222291754428"
                    rel="noreferrer"
                  >
                    네이버 리뷰 벌점 으뜸은? 계양구 진요양원
                  </a>
                </div>
              </div>
            </div>
            <IntroBar text="네이버 실제 리뷰" Icon={ReviewStar} />
            <ReviewBox />
            <Link to="/info" className="facility-container-info">
              <h1>입소안내 바로가기</h1>
            </Link>
            <Map />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Care;
