import { CareSlick } from '../slicks/Slicks';
import './Care-Intro.css';

export const CareIntro = () => {
  return (
    <>
      <div className="care-intro ">
        <div className="care-intro-text">
          <h1 className="care-intro-title">
            전문 요양보호사와
            <br />
            어르신 전담 매니저
          </h1>
          <p className="care-intro-desc">
            전문 요양보호사를 요양원의
            <br />
            필수인력 보다 더 많이 채용하여
            <br />
            어르신의 식사도움, 배변관리,
            <br />
            기저귀케어 등의 돌봄을 합니다.
          </p>
          <span className="care-intro-sub-bar">
            어르신 전담 매니저 | 심신 체크
          </span>
        </div>
        <div className="care-intro-img"></div>
      </div>
      <strong className="care-intro-sub-notice">
        &#8251; 진 요양원은 어르신 전담 매니저를 두어
        <br />
        어르신들의 심신을 매일 체크하고 케어합니다.
      </strong>
    </>
  );
};

export const CareImg = () => {
  return (
    <div className="care-img">
      <CareSlick />
    </div>
  );
};
