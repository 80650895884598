import { Link } from 'react-router-dom';
import { IntroBar, KindContact } from '../../components/detail/Detail';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Map from '../../components/map/Map';
import RehabilitationIntro from '../../components/rehabilitation-intro/Rehabilitation-Intro';
import { ReviewBox } from '../../components/review/Review-Box';
import { behabilitationIcon, ReviewStar } from '../../img/Icons';
import './Rehabilitation.css';

const Rehabilitation = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="rehabilitation-container">
              <div className="rehabilitation-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="재활치료" Icon={behabilitationIcon} />
            <RehabilitationIntro />
            <div className="facility-container-blog facility-container-blog-main">
              <h1 className="facility-container-blog-title facility-container-blog-title-main">
                재활치료 블로그 바로가기
              </h1>
              <div className="facility-container-blog-list">
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri9902&logNo=222277801486"
                    rel="noreferrer"
                  >
                    인천 진요양원 재활치료 이야기
                  </a>
                </div>
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri58&logNo=222252071293&parentCategoryNo=6&categoryNo=&viewDate=&isShowPopularPosts=false&from=postView"
                    rel="noreferrer"
                  >
                    인천요양원 중 재활가능한 곳
                  </a>
                </div>
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri58&logNo=222224693944&parentCategoryNo=6&categoryNo=&viewDate=&isShowPopularPosts=false&from=postView"
                    rel="noreferrer"
                  >
                    인천요양원추천: 경증치매시면 진요양원
                  </a>
                </div>
              </div>
            </div>
            <IntroBar text="네이버 실제 리뷰" Icon={ReviewStar} />
            <ReviewBox />
            <Link to="/info" className="facility-container-info">
              <h1>입소안내 바로가기</h1>
            </Link>
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rehabilitation;
