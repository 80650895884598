import { RehabilitationSlick } from '../slicks/Slicks';
import './Rehabilitation-Intro.css';

const RehabilitationIntro = () => {
  return (
    <>
      <div className="rehabilitation-intro">
        <div className="rehabilitation-intro-text">
          <h1 className="rehabilitation-intro-text-title">
            물리치료 설비완비 &amp;{' '}
            <br className="rehabilitation-intro-text-title-br" /> 하체근력강화
            통증치료
          </h1>
          <p className="rehabilitation-intro-text-desc">
            진요양원은 전문 물리치료사가
            <br />
            상주하며 어르신들의 맞춤형
            <br />
            재활치료에 힘쓰고 있습니다.
            <br />
            근력강화, 통증치료, 디스크관리 등
            <br />
            어르신들이 좀 더 행복한 일상을
            <br />
            누리실 수 있도록 노력합니다.
          </p>
        </div>
        <div className="rehabilitation-intro-img"></div>
      </div>
      <RehabilitationSlick />
    </>
  );
};

export default RehabilitationIntro;
