export const Loading = () => {
  return (
    <div
      className="loading-container"
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <p
        style={{
          fontSize: 36,
          textAlign: 'center',
        }}
        className="loading-container-text"
      >
        진 요양원에 <br />
        오신걸 환영합니다.
      </p>
      <img
        className="loading-container-img"
        style={{
          width: 400,
          height: 400,
        }}
        src="https://miro.medium.com/max/1000/1*DXvI3dy2rKOMzV8S3fKFMQ.gif"
        alt="loading"
      />
    </div>
  );
};
