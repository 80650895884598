import { ReviewFace, ReviewStar } from '../../img/Icons';
import { IntroBar } from '../detail/Detail';
import './Review-Box.css';

const reviewData = [
  {
    id: 'jj*',
    date: '2021.01.19',
    content:
      '어머니께서 전혀 걷지 못하셨는데, 이제는 조금씩 걷는다고 하시던데.. 원장님과 물리치료사 선생님 덕분입니다. 감사해요',
  },
  {
    id: 'dn*',
    date: '2021.01.04',
    content:
      '우리 할머니가 너무 행복해하세요!! 시설장님 감사합니다~ 계양구 진요양원 진짜 최고인 것 같아요! 소문대로 정말 친절하고..코로나때문에 걱정했는데 걱정이 싹 날라갔어요.. ',
  },
  {
    id: 'le*',
    date: '2020.11.30',
    content:
      '어제 아버지랑 통화했습니다. 너무 잘해주신다고 하셔서 눈물이 났습니다. 감사합니다.',
  },
];

const reviewDataMain = [
  {
    id: 'jj*',
    date: '2021.01.19',
    content:
      '어머니께서 전혀 걷지 못하셨는데, 이제는 조금씩 걷는다고 하시던데.. 원장님과 물리치료사 선생님 덕분입니다. 감사해요',
  },
  {
    id: 'dn*',
    date: '2021.01.04',
    content:
      '우리 할머니가 너무 행복해하세요!! 시설장님 감사합니다~ 계양구 진요양원 진짜 최고인 것 같아요! 소문대로 정말 친절하고..코로나때문에 걱정했는데 걱정이 싹 날라갔어요.. ',
  },
  {
    id: '배*',
    date: '2020.01.19',
    content:
      '요양원이라는 곳에 편견이 있었는데  진 요양원 가보고 정말 저보다 낫구나 하는 생각 들었어요. 감사한 마음에 리뷰남깁니다. ',
  },
  {
    id: 'le*',
    date: '2020.11.30',
    content:
      '어제 아버지랑 통화했습니다. 너무 잘해주신다고 하셔서 눈물이 났습니다. 감사합니다.',
  },
  {
    id: 'aj*',
    date: '2020.11.16',
    content:
      '어머니께서 갑자기 못 일어나셔서 파킨슨 진단받고 얼마나 속상했는지 몰라요.. 등급받고 이렇게 진요양원에 모시니 마음이 편안합니다.',
  },
  {
    id: '한*',
    date: '2020.10.26',
    content:
      '저희 할머니 사진보니까 자주 웃으시더라고요. 평소에 자주 안웃으셨는데.. 너무 좋네요',
  },
  {
    id: '토*',
    date: '2020.10.28',
    content: '계양구에서 진짜 제일 좋은 요양원 ♡',
  },
  {
    id: 'ggsle*',
    date: '2020.11.29',
    content:
      '계신 분들이 다 친절하셔서 감동이예요 항상 감사드립니다... 먼저 소식도 전해주시고 감사합니다..',
  },
];

export const ReviewBox = () => {
  return (
    <>
      {reviewData &&
        reviewData.map((review) => (
          <div key={review.date} className="review-box">
            <div className="review-box-id">
              <div className="review-box-id-avatar">
                <ReviewFace color="#ccc" />
              </div>
              <div className="review-box-id-name">{review.id}</div>
            </div>
            <div className="review-box-stars">
              <div className="review-box-stars-star">
                <ReviewStar color="#000" />
                <ReviewStar color="#000" />
                <ReviewStar color="#000" />
                <ReviewStar color="#000" />
                <ReviewStar color="#000" />
              </div>
              <div className="review-box-stars-score">5.0</div>
            </div>
            <p className="review-box-date">{`${review.date} | 영수증`}</p>
            <p className="review-box-text">{review.content}</p>
          </div>
        ))}
    </>
  );
};

export const ReviewBoxMain = () => {
  return (
    <>
      <IntroBar text="네이버 실제 리뷰 보러가기" Icon={ReviewStar} />
      <div className="review-box-main-container">
        {reviewDataMain &&
          reviewDataMain.map((review) => (
            <div key={review.id} className="review-box-main">
              <div className="review-box-id-main">
                <div className="review-box-id-avatar-main">
                  <ReviewFace color="#ccc" />
                </div>
                <div className="review-box-id-name-main">{review.id}</div>
              </div>
              <div className="review-box-stars-main">
                <div className="review-box-stars-star-main">
                  <ReviewStar color="#000" />
                  <ReviewStar color="#000" />
                  <ReviewStar color="#000" />
                  <ReviewStar color="#000" />
                  <ReviewStar color="#000" />
                </div>
                <div className="review-box-stars-score-main">5.0</div>
              </div>
              <p className="review-box-date-main">{`${review.date} | 영수증`}</p>
              <p className="review-box-text-main">{review.content}</p>
            </div>
          ))}
      </div>
    </>
  );
};
