import './Map.css';
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';
import { mapIcon } from '../../img/Icons';
import { IntroBar } from '../../components/detail/Detail';

function NaverMapAPI() {
  const navermaps = window.naver.maps;

  return (
    <NaverMap
      className="naver-map"
      mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
      defaultCenter={{ lat: 37.5355497, lng: 126.738247 }} // 지도 초기 위치
      defaultZoom={17} // 지도 초기 확대 배율
      zoomControl={true}
      scrollWheel={false}
    >
      <Marker
        key={1}
        position={new navermaps.LatLng(37.5355497, 126.738247)}
        animation={1}
        title="진요양원"
        onClick={() => {
          window.open('https://blog.naver.com/arumdri9902');
        }}
      />
    </NaverMap>
  );
}

const Map = () => {
  return (
    <>
      <div className="after-review-img" id="maps"></div>
      <IntroBar text="오시는 길" Icon={mapIcon} />
      <div className="map-container">
        <h1 className="map-container-title">진 요양원 오시는 길</h1>

        <div className="map-container-map">
          <RenderAfterNavermapsLoaded
            className="home-container-map_box_map"
            ncpClientId="2xo4rokjm1"
            error={<p>Maps Load Error</p>}
            loading={<p>Maps Loading...</p>}
          >
            <NaverMapAPI />
          </RenderAfterNavermapsLoaded>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://m.place.naver.com/place/1631826674/location?entry=pll&subtab=location"
          className="map-naver-link-mobile"
        >
          네이버 지도 보기
        </a>
        <div className="map-container-text">
          <h1 className="map-container-text-addr">
            주소 : 인천광역시 계양구 오조산로 53
            <br />
            연세차메디컬빌딩 8층 9층 진요양원
          </h1>
          <p className="map-container-text-desc">
            진 요양원은 계양구청 주차장 바로 앞 평안교회 옆 건물입니다.
            <br />
            한빛정형외과와 같은 건물에 위치하고 있으며
            <br />
            은행마을 태산아파트 맞은편입니다.
          </p>
          <div className="map-container-text-bus">
            버스운행 : 88번, 2-1번, 14번, 79번, 300번, 302번, 1500번
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://map.naver.com/v5/search/%EA%B3%84%EC%96%91%EA%B5%AC%20%EC%A7%84%EC%9A%94%EC%96%91%EC%9B%90/place/1631826674?c=14104638.1906075,4514356.5585797,13,0,0,0,dh"
            className="map-naver-link-desk"
          >
            네이버 지도 보기
          </a>
        </div>
        <a className="map-container-text-link" href="tel:032-511-8495">
          <strong>상담전화</strong> 032-511-8495
          <br />
          <span>지금 통화하기 버튼</span>
        </a>
      </div>
    </>
  );
};

export default Map;
