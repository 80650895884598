import { IntroBar, KindContact } from '../../components/detail/Detail';
import {
  FacilityImg,
  FacilityIntro,
} from '../../components/facility-intro/Facility-intro';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Map from '../../components/map/Map';
import { facilityIcon, ReviewStar } from '../../img/Icons';
import { ReviewBox } from '../../components/review/Review-Box';
import './Facility.css';
import { Link } from 'react-router-dom';

const Facility = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="facility-container">
              <div className="facility-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="시설안내" Icon={facilityIcon} />
            <FacilityIntro />
            <FacilityImg />
            <div className="facility-container-box-items">
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text"></div>
                <div className="facility-container-box-item-img1"></div>
              </div>
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text">
                  <h1 className="facility-container-box-item-text-title">
                    채광이 좋은 넓은 거실 <br /> 깨끗한 최신시설
                  </h1>
                  <p className="facility-container-box-item-text-desc">
                    외부생활이 힘든 어르신들이 큰 거실에서 산책하시고 재활치료
                    하실 수 있도록 설계
                  </p>
                  <div className="facility-container-box-item-img2"></div>
                </div>
              </div>
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text">
                  <h1 className="facility-container-box-item-text-title">
                    재활치료가 가능한 <br /> 물리치료설비 구축
                  </h1>
                  <p className="facility-container-box-item-text-desc">
                    근력강화 뿐만 아니라 적외선 치료, 무릎근력 강화 등 다양한
                    설비와 물리치료사 상주
                  </p>
                </div>
                <div className="facility-container-box-item-img3"></div>
              </div>
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text">
                  <h1 className="facility-container-box-item-text-title">
                    상급병실료 없는 <br /> 채광좋은 2인~4인 룸
                  </h1>
                  <p className="facility-container-box-item-text-desc">
                    어르신들 방이 전부 채광이 좋고 기저귀 관리를 밖에서 따로 해
                    시설전체가 쾌적합니다.
                  </p>
                </div>
                <div className="facility-container-box-item-img4"></div>
              </div>
            </div>
            <div className="facility-container-blog">
              <h1 className="facility-container-blog-title">
                시설안내 블로그 바로가기
              </h1>
              <div className="facility-container-blog-list">
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/arumdri9902/222265751883"
                    rel="noreferrer"
                  >
                    인천요양원 어르신 곁에 핀 꽃
                  </a>
                </div>
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri9902&logNo=222284279075&categoryNo=0&parentCategoryNo=1&viewDate=&currentPage=1&postListTopCurrentPage=1&from=postView"
                    rel="noreferrer"
                  >
                    인천 계양구 요양원 추천! 소문난 진 요양원
                  </a>
                </div>
                <div className="facility-container-blog-list-item">
                  <span>&raquo;</span>
                  <a
                    target="_blank"
                    href="https://blog.naver.com/PostView.nhn?blogId=arumdri9902&logNo=222291754428"
                    rel="noreferrer"
                  >
                    네이버 리뷰 별점 으뜸은? 계양구 진 요양원
                  </a>
                </div>
              </div>
            </div>
            <IntroBar text="네이버 실제 리뷰" Icon={ReviewStar} />
            <ReviewBox />
            <Link to="/info" className="facility-container-info">
              <h1>입소안내 바로가기</h1>
            </Link>
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Facility;
