import "./intro.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Map from "../../components/map/Map";

const Intro = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="intro-container">
              <div className="intro-container-main-img"></div>
              <div className="intro-container-main-text">
                <a
                  className="intro-container-text-link"
                  href="tel:032-511-8495"
                >
                  <strong>친절상담</strong> 032-511-8495
                  <br />
                </a>
                <h5>
                  요양원 비용에서 등급까지!
                  <br />
                  무엇이든 물어보세요
                </h5>
                <strong className="intro-container-text-str">
                  "요양원 믿을 수 있을까?"
                </strong>
                <p className="intro-container-text-desc">
                  진 요양원은 요양원에 대한 기존의 편견을 깨고
                  <br />
                  <span>어르신을 진심으로 위하는 마음</span>
                </p>
                <p className="intro-container-text-desc">
                  이것을 운영철학으로 하여
                  <br />
                  실제로 어르신이 행복한 삶을
                  <br />살 수 있도록 노력하고 있습니다.
                </p>
                <p className="intro-container-text-desc">
                  어르신들을 진심으로 마주하고
                  <br />
                  보호자님들과 시설장이 직접
                  <br />
                  어르신의 상황을 공유하여 어르신, 보호자님
                  <br />
                  모두가 행복할 수 있는 요양원을 추구합니다.
                </p>
              </div>
              <div className="intro-container-book">
                <div className="intro-container-book-box">
                  <div className="intro-container-book-box-img"></div>
                  <h3 className="intro-container-book-box-text">
                    현직요양보호사 쓴 요양원 이야기
                    <br />- 전계숙 에세이
                  </h3>
                </div>
                <div className="intro-container-book-text">
                  <h1>이상적인 요양원이란?</h1>
                  <p className="intro-container-book-desc">
                    어르신들이 한 시간씩 햇빛이 드는
                    <br />
                    창가에 앉아서 밖을 바라볼 수 있는 곳
                  </p>
                  <p className="intro-container-book-desc">
                    널찍한 소파에 비스듬히 누워 요양보호사와
                    <br />
                    가요무대를 함께 시청할 수 있는 곳
                  </p>
                  <p className="intro-container-book-desc">
                    서서히 굳어 가는 근육을
                    <br />
                    매일 풀어줄 수 있는 물리치료시설이 완벽한 곳
                  </p>
                  <p className="intro-container-book-desc">
                    - 전계숙 에세이
                    <br />
                    [돌봄이 아니라 인생을 배우는 중입니다] 중에서
                  </p>
                </div>
              </div>

              <h1 className="intro-container-box-text">
                진 요양원은
                <br />
                요양원 에세이 내용 중에 나온
                <br />
                이상적인 요양원의
                <br />세 가지 항목은 적어도
                <br />
                실현하려고 노력합니다.
              </h1>

              <div className="intro-container-box-items">
                <div className="intro-container-box-item">
                  <div className="intro-container-box-item-img1"></div>
                  <p className="intro-container-box-item-text">
                    햇빛이 드는 창가에서 식사도 하시고
                    <br />
                    도란도란 이야기도 나누는 진 요양원
                  </p>
                </div>
                <div className="intro-container-box-item">
                  <div className="intro-container-box-item-img2"></div>
                  <p className="intro-container-box-item-text">
                    요양보호사와 가요무대를 시청하며
                    <br />
                    휴식을 즐기는 진 요양원
                  </p>
                </div>
                <div className="intro-container-box-item">
                  <div className="intro-container-box-item-img3"></div>
                  <p className="intro-container-box-item-text">
                    굳어가는 근육을 풀어줄 수 있는
                    <br />
                    물리치료사 있는 진 요양원
                  </p>
                </div>
              </div>
            </div>
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Intro;
