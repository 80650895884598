import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { IntroBar } from '../../components/detail/Detail';
import {
  behabilitationIcon,
  careIcon,
  facilityIcon,
  ReviewStar,
} from '../../img/Icons';
import {
  FacilityImg,
  FacilityIntro,
} from '../../components/facility-intro/Facility-intro';
import MainIntro from '../../components/main/Main-Intro';
import RehabilitationIntro from '../../components/rehabilitation-intro/Rehabilitation-Intro';
import { CareImg, CareIntro } from '../../components/care-intro/Care-Intro';
import { ReviewBox } from '../../components/review/Review-Box';
import Map from '../../components/map/Map';

const Main = () => {
  return (
    <>
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
              <MainIntro />
              <IntroBar text="시설안내" Icon={facilityIcon} />
              <FacilityIntro />
              <FacilityImg />
              <IntroBar text="재활치료" Icon={behabilitationIcon} />
              <RehabilitationIntro />
              <IntroBar text="전문케어서비스" Icon={careIcon} />
              <CareIntro />
              <CareImg />
              <a
                target="_blank"
                rel="noreferrer"
                href="https://map.naver.com/v5/search/%EC%A7%84%EC%9A%94%EC%96%91%EC%9B%90/place/1631826674?c=14104638.1906075,4514356.5585797,13,0,0,0,dh&placePath=%3Fentry%253Dpll"
              >
                <IntroBar text="네이버 실제 리뷰 보러가기" Icon={ReviewStar} />
              </a>
              <ReviewBox />
              <Map />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Main;
