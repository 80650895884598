import './Facility-intro.css';

export const FacilityIntro = () => {
  return (
    <>
      <div className="facility-intro">
        <div className="facility-intro-text">
          <h1 className="facility-intro-title">
            조용하고 좋은 위치
            <br />
            메디컬 빌딩에 입점
          </h1>
          <p className="facility-intro-desc">
            인천 계양구 계양구청 앞에 위치한
            <br />
            진 요양원은 대형병원 한림병원과
            <br />
            세종병원이 도보 5분 거리입니다.
            <br />
          </p>
          <span className="facility-intro-sub-bar">
            협력병원 | 인성의료재단 한림병원
          </span>
        </div>
        <div className="facility-intro-img"></div>
      </div>
      <strong className="facility-intro-sub-notice">
        &#8251; 어르신의 병원진료가 필요한 경우 전담매니저 혹은
        <br />
        시설장 이동행합니다. 자체적으로 휠체어 이동가능 차량 구비
      </strong>
    </>
  );
};

export const FacilityImg = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text">
        <h1 className="facility-img-text-title">
          채광이 좋은 넓은 거실과 깨끗한 최신시설
        </h1>
        <p className="facility-img-text-desc">
          외부생활이 힘든 어르신들이 큰 거실에서 산책하시고
          <br />
          재활치료 하실 수 있도록 설계되어 있습니다.
        </p>
      </div>
      <div className="facility-img-img">
        <div className="facility-img-img-item1"></div>
        <div className="facility-img-img-item2"></div>
        <div className="facility-img-img-item3"></div>
        <div className="facility-img-img-item4"></div>
        <div className="facility-img-img-item5"></div>
        <div className="facility-img-img-item6"></div>
      </div>
    </div>
  );
};
